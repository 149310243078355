import React, { useEffect, useState, useRef } from 'react';
import { Toast } from 'bootstrap'

function ToastAluno(props) {
    var [toast, setToast] = useState(true);

    const toastRef = useRef();

    useEffect(() => {
        var myToast = toastRef.current
        var bsToast = Toast.getInstance(myToast)

        if (!bsToast) {

            bsToast = new Toast(myToast, { autohide: false })
            bsToast.show()
        }
        else {

            if (props.aluno.isFound) {
                setToast(toast => true)
            }

            toast ? bsToast.show() : bsToast.hide()

        }


    })

    const handletoggle = () => {
        setToast(toast => false)


    }
    return (
        <>
            {
                props.aluno.isFound ?

                    < div className="py-2" >

                        <div className="toast tam-caixa  " role="alert" ref={toastRef}>
                            <div className="toast-header">
                                <strong className="me-auto"></strong>
                                <small>{new Date().toLocaleString()}</small>
                                <button type="button" className="btn-close" onClick={() => handletoggle()} aria-label="Close"></button>
                            </div>
                            <div className="toast-body">

                                <div className="" role="alert">
                                    <ul>
                                        <li><b>Nome do Aluno(a):</b> {props.aluno.nome}</li>
                                        <li><b>Curso:</b> {props.aluno.curso}</li>
                                        <li><b>Período:</b> {props.aluno.periodo}</li>
                                        <li><b>Tipo:</b> {props.aluno.tipo}</li>
                                        <li><b>Situação:</b> {props.aluno.sit}</li>
                                        <li><b>Início:</b> {new Date(props.aluno.dt_ini).toLocaleDateString()}</li>
                                        <li><b>Final:</b> {new Date(props.aluno.dt_fim).toLocaleDateString()}</li>
                                        <li><b>Turno:</b> {props.aluno.turno}</li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div >
                    : ''
            }
        </>
    )
}


const ResultComponent = (props) => {


    return (
        <>

            {
                props.aluno ? props.aluno.isFound ?
                    <div className="row row-style" style={{ paddingTop: '15px' }}>
                        <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                            <ToastAluno aluno={props.aluno} /></div>

                    </div>
                    :
                    ''
                    : ''
            }
        </>

    )
}

export default ResultComponent;