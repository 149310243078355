import React from "react"
import Logo from '../static/images/logo_branca_limpa.svg'

const Header = () => {

    return (
        <header>
            <div id="div-header">
                <div >
                    <img src={Logo} width="160px" height="40px" alt="UCEFF" />
                </div>

            </div>
        </header>
    )
}

export default Header;