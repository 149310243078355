import React, { useState } from "react";

import 'bootstrap/dist/css/bootstrap.min.css'
import '../static/sass/style.scss'
import Header from "./header";
import CustomForm from "./customForm";
import Resultado from "./resultComponent";
import { useAlert } from 'react-alert'


const Home = () => {

    const [aluno, setAluno] = useState(null);
    const alert = useAlert();

    const handleCpf = (aluno) => {

        setAluno(aluno)

        if (!aluno.isFound)
            alert.show(<div style={{ fontSize: '15px' }}>Aluno(a) não encontrado!</div>)
    }

    const handleIsfound = (aluno) => {
        setAluno(null)
    }

    return (
        <>
            <Header />
            <div className="container-fluid">
                <div className="row row-style " >

                    <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                        <CustomForm handleCpf={handleCpf} />
                    </div>
                </div>

                <Resultado aluno={aluno} />
            </div>
        </>
    )

}

export default Home;