import React, { useEffect, useState } from "react";
import RestAutoViacao from '../fetchData/data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSearch } from '@fortawesome/free-solid-svg-icons'
import MaskInput from 'react-maskinput'
import { useAlert } from 'react-alert'

const CustomForm = (props) => {

    const element = <FontAwesomeIcon icon={faSearch} size="1x" />
    const [isFinding, setIsFinding] = useState(false);
    const [Cpf, setCpf] = useState(null);

    const [botao, setBotao] = useState(false);

    const alert = useAlert();

    useEffect(() => {
        setIsFinding(false);

    }, [Cpf]);

    const handleCpfChange = (input) => {
        setBotao(true)
        input.target.value.length === 14 ?
            setBotao(botao => true) : setBotao(botao => false)

    }

    const handleSubmit = (evt) => {
        evt.preventDefault();

        const cpf = document.getElementById('cpf').value;

        document.getElementById('cpf').value = '';

        RestAutoViacao.post('/rest/restautoviacao/buscaAluno.json', { 'token': 'eaee926fb7eabcf0aadf03b65947907c', 'cpf': cpf }).then(response => {

            if (response.data.result[0]) {
                var neww = response.data.result[0];
                neww.isFound = true;
                setCpf(cpf);
                props.handleCpf(neww)
            }
            else {
                props.handleCpf({ isFound: false })
                setCpf(cpf);

            }

            setIsFinding(false);
            setBotao(false)
        }).catch(
            () => {
                alert.error(<div style={{ fontSize: '15px' }}>Erro ao conectar no servidor!!</div>);
                setIsFinding(false);
                setBotao(false)
            }
        )
        setIsFinding(true);


    }

    return (
        <div className="container form">
            <form className="container" onSubmit={handleSubmit}>
                <div className="form-floating mb-3">
                    <MaskInput type="text" className="form-control" id="cpf" placeholder="CPF" maskChar="_" mask="000.000.000-00" size={14} onChange={handleCpfChange} />
                    <label htmlFor="cpf" className="form-label">CPF</label>
                </div>
                <div className="botao-buscar">

                    {isFinding ?
                        <button className="btn btn-primary button-effect" type="button" >
                            <span className="spinner-border spinner-border-md span-form " role="status" aria-hidden="true"></span>
                            Buscando...
                        </button>

                        : <button className="btn btn-primary button-effect" style={{ width: '142.81px', height: '48.38px' }} type="submit" disabled={!botao}>
                            <span style={{ marginRight: '12px', fontSize: '18px' }} aria-hidden="true">{element}</span>
                            Buscar
                        </button>


                    }
                </div>
            </form >


        </div >
    )
}

export default CustomForm;