
import Home from './components/home';
import React from 'react';
import { positions, Provider } from 'react-alert';
import TemplateDark from 'react-alert-template-oldschool-dark'

const options = {
  timeout: 6000,
  transition: 'scale',
  position: positions.TOP_CENTER,

}

function App() {
  return (
    <Provider template={TemplateDark} {...options}>
      <Home />
    </Provider>
  );
}

export default App;
